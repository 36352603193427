@import '../abstracts/variables.sass';

.aiNormalInput-outer {
  margin-top: 1.3rem;
  label {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 600 !important;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }
}

.aiNormalInput {
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 4.5rem;
  width: 100%;
  transition: $normal-transition;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--input-background-color);
  margin-top: 0.3rem;
  padding: 1rem;

  &:hover {
    border: $input-border-2;
  }

  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  input,
  input:active,
  input:focus {
    outline: none;
    background-color: var(--input-background-color);
  }

  input {
    width: 100%;
    color: var(--main-text-color);
  }
}

.aiNormalInput-validation-text {
  margin-bottom: 0;
  padding: 0; /* Ensure no padding */
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  margin-left: 0.2rem;

  width: 100%;
  height: 0rem;
  white-space: nowrap;
}

@media only screen and (max-width: 550px) {
  .aiNormalInput-validation-text {
    overflow: hidden;
    height: 1.5rem;
    line-height: 1;
  }
  .slide-in-text {
    display: inline-block;
    animation: slide-in 12s linear infinite;
    animation-delay: 3s;
    white-space: nowrap;
    line-height: 1;
    vertical-align: middle;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-98%);
  }
}
