@import '../abstracts/variables.sass';

.collapseList {
  margin-top: 2rem;
  font-family: var(--main-font-primary);
  &__item {
    transition: $normal-transition;
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--main-border-color);
    &--top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        &-tag {
          padding: 0.2rem 1.2rem;
          border-radius: 2rem;
          font-weight: 600;
          font-size: 1.2rem;
          &-green {
            background-color: #dcfce7;
            color: #16a34a;
          }

          &-red {
            background-color: #fee2e2;
            color: #dc2626;
          }

          &-yellow {
            background-color: #fef9c3;
            color: #ca8a04;
          }

          &-blue {
            background-color: #dbeafe;
            color: #2563eb;
          }
        }
        &-title {
          color: var(--main-text-color);
          font-size: 1.4rem;
          font-weight: 400;
        }
      }

      &-right {
        margin-right: 0.5rem;
        svg {
          transition: $normal-transition;
          width: 1.2rem;
          height: 1.2rem;
          fill: var(--main-text-color);
          rotate: 90deg;
        }

        &-open {
          svg {
            rotate: -90deg;
          }
        }
      }
    }

    &--bottom {
      margin-top: 1rem;
    }
  }
}
