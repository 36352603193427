@import 'bulma/css/bulma.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import '../abstracts/variables.sass';

@font-face {
  font-family: 'Lufga';
  src: url('../../fonts/Lufga-Medium.woff2') format('woff2'),
    url('../../fonts/Lufga-Medium.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'inter';
  src: url('../../fonts/inter.woff') format('woff');
  font-weight: 80;
  font-style: normal;
  font-display: swap;
}

// Geist Sans fonts import
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('../../fonts/geist-fonts/Geist[wght].woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* ----------common styles----------- */
:root {
  --main-font-primary: 'Geist Sans', sans-serif;
  --main-background-color: #ffffff;
  --main-border-color: #d1d1d1;
  // --main-background-color: #f2f2f2;
  // --main-background-color: #dedede;
  --header-color: #f2f2f2;
  --sidebar-color: #fafafa;
  --sidebar-hover-color: #f3f3f3;
  --sidebar-hover-color-2: #f3f3f375;
  --icon-color: #9999a9;
  // --containers-background-color: #ebebeb;
  --containers-background-color: #ffffff;
  --sidebar-background-color: #ffffff;
  // --containers-border-color: #dcdbdb;
  --containers-border-color: #ada5a5;
  --white-border-color: #ebebeb;
  // --white-border-color: #fff;
  --sidebar-sublink-hover-color: #2e333d;
  --subPageLinkHolder-link-hover-color: #efefef;
  // --subPageLinkHolder-link-hover-color: #d8d8d8;
  --subPageLinkHolder-link-selected-color: #fff;
  --input-border-color: #cecece;
  --input-border-hover-color: #9999a9;
  // --input-background-color: #fff;
  --input-background-color: #ffffff;
  --store-btn-color: #fff;
  --gray-btn-light-background-color: #e2e2e2;
  --gray-btn-light-hover-background-color: #d7d7d7;
  --gray-btn-light-text-color: #9999a9;
  --subPageList-hover-color: #f0f0f0;
  --topbar-background-color: #fff;
  --sub-text-color: #4c4c53;
  --login-background-color: #dedffe;
  --black-white-color: #000000;

  //white label
  --main-brand-color: #111111;
  --main-brand-hover-color: #434343;
  --secondary-brand-color: #ffdd33;
  --secondary-brand-hover-color: #ffe73d;
  --main-btn-color: #0e0e0e;
  --main-btn-hover-color: #202020;
  --main-btn-text-color: #ffffff;
  --main-box-shadow: 0 8px 10px -5px #1111116b, 0 4px 23px 0 #1111111f,
    0 8px 10px -5px #11111133;
  --main-text-color: #2e333d;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: var(--main-font-primary);
  /* font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Helvetica', 'Arial', sans-serif; */
  background-color: var(--main-background-color) !important;
  scroll-behavior: smooth;

  @media only screen and (max-width: 420px) {
    & {
      font-size: 55%;
    }
  }

  @media only screen and (max-width: 320px) {
    & {
      font-size: 50%;
    }
  }
}

hr {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: var(--main-font-primary);
  /* color: #283252; */
}

h1 {
  /* font-size: 2rem;
  font-weight: 600; */
  line-height: 1.125;
}

h2 {
  /* font-size: 1; */
  font-family: var(--main-font-primary);
  /* font-weight: 100; */
}

p {
  /* font-size: 1; */
  font-family: var(--main-font-secondary) !important;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

input,
select,
textarea {
  outline: none;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--main-text-color) !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--main-text-color) !important;
}
/* ----------------------------------common styles--------------------------------- */

/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/*---------------------------------- mui styles start-------------------------------------*/
/* .MuiDataGrid-root {
  font-size: 1.5rem !important;
} */

/* datatable common styles */
.css-146mq4w-MuiDataGrid-root {
  background-color: $main-white-color !important;
}

/* datatable header styles */
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-family: var(--main-font-primary);
  font-size: 1.2rem !important;
  color: var(--main-text-color) !important;
  text-transform: uppercase !important;
}

/* datatable header border-right */
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
  border-right: 0.5px solid rgba(224, 224, 224, 1) !important;
  border-left: 0.5px solid rgba(224, 224, 224, 1) !important;
}

/* datatable header dividers display none*/
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

/* datatable header sorting menu */
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  width: auto !important;
  visibility: visible !important;
}

.css-1otxwt9-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

/* all datatable icons */
.css-ptiqhd-MuiSvgIcon-root {
  height: 1.7rem !important;
  width: 1.7rem !important;
}

/*datatable menu list */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 1.3rem !important;
}

.MuiDataGrid-columnHeaders {
  color: rgb(48, 48, 48) !important;
  font-weight: 900 !important;
  font-size: 1.5rem !important;
  // border-right: 0.5px solid rgba(224, 224, 224, 1) !important;
  border-right: none !important;
}

.css-1vtqas6-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  font-size: 2rem !important;
  color: rgb(85, 85, 85) !important;
}

/* pagination numbers*/
.css-levciy-MuiTablePagination-displayedRows {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: var(--main-text-color) !important;
}
.MuiTablePagination-root {
  font-size: 1.4rem;
}

/* pagination buttons */
.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  font-size: 3rem !important;
  border: 1px solid var(--main-btn-color) !important;
  background-color: var(--main-btn-color) !important;
  height: 3rem !important;
  width: 3rem !important;
  margin-right: 1rem !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  color: $main-white-color !important;
}

/* pagination buttons disabled */
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

/* pagination buttons svg*/
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions
  svg {
  font-size: 2rem !important;
}

/* row selector check boxes svg*/
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root svg {
  width: 1.4rem !important;
  height: 1.4rem !important;
  fill: var(--main-text-color) !important;
}

/* datatable rows font styles */
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-family: var(--main-font-primary);
  font-size: 1.4rem;
  color: var(--main-text-color) !important;
  padding: 1rem !important;
}

/* datatable rows hover styles */
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-cell:hover {
  color: var(--main-text-color) !important;
}

/* datatable cell content styles */
.MuiDataGrid-cellContent {
  color: var(--main-text-color) !important;
}

/* datatable focus outline styles */
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-146mq4w-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-ptiqhd-MuiSvgIcon-root {
  font-size: 1.4rem !important;
}

.MuiTablePagination-displayedRows,
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}
/*---------------------------------- mui styles end-------------------------------------*/

/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/*---------------------- datatable action button & image cell start---------------------*/
.cellAction {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.datatableActionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  border: $input-border-small;
  transition: $normal-transition;

  &:hover {
    border: $input-border-small-hover;
  }

  span {
    font-weight: 400;
    font-size: 1.3rem;
    margin-left: 0.5rem;
    border-bottom: 0;
    line-height: 1.2rem;
    color: var(--main-text-color);
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--main-text-color);
  }
}

.viewButton svg {
  fill: $web-color-success;
}

.editButton svg {
  fill: $web-color-primary;
}

.deleteButton svg {
  fill: $web-color-danger;
}

.press {
  transition: all 0.3s ease;
  backface-visibility: hidden;
}

.dataGrid__imageCell {
  height: 10rem;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 70%;
    width: 70%;
    object-fit: contain;
  }
}

.mui-table-status-text {
  color: var(--main-text-color);
}
/*---------------------- datatable action button & image cell end---------------------*/

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/*------------------- Progress Bar Styles Start ------------------*/
progress {
  border: 0;
  height: 1rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: width 0.5s ease;
}
progress::-webkit-progress-bar {
  border: 0;
  height: 0.8rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: width 0.5s ease;
}
progress::-webkit-progress-value {
  border: 0;
  height: 0.8rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: width 0.5s ease;
}
progress::-moz-progress-bar {
  border: 0;
  height: 0.8rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  transition: width 0.5s ease;
}
/*------------------- Progress Bar Styles End --------------------*/

/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/*---------- SUB PAGE COMMON STYLES START ----------- */
/* .subPage {
  display: flex;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
} */
.subPage {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subPage__container {
  width: 100%;
}
.subPage__table--container {
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sync-history-table-height {
  height: 37.3rem;
}

/*---------- SUB PAGE COMMON STYLES END ----------- */

/*---------- SUB-PAGE-LINK-HOLDER COMMON STYLES START ----------- */
.subPageLinkHolderItems__img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}
/*---------- SUB-PAGE-LINK-HOLDER COMMON STYLES END ----------- */

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/* --------SquareGrayButton usable classes start-------------- */
.squareGrayButton-delete {
  background-color: rgba(255, 98, 88, 0.2) !important;
  border: none !important;
  transition: $normal-transition !important;
  padding: 1rem !important;

  &:hover {
    background-color: $web-color-danger !important;

    svg {
      fill: $main-white-color !important;
    }
  }

  svg {
    fill: $web-color-danger !important;
    margin-right: 0rem !important;
  }
}
.squareGrayButton-add {
  background-color: rgba(233, 231, 231, 0.574) !important;
  border: 1rem solid;
  transition: $normal-transition !important;
  padding: 1rem !important;

  &:hover {
    background-color: var(--main-btn-color) !important;

    svg {
      fill: var(--main-text-color) !important;
    }
  }

  svg {
    fill: var(--main-text-color) !important;
    margin-right: 0rem !important;
  }
}

.squareGrayButton-mobile-view {
  padding: 1rem !important;
  svg {
    margin-right: 0 !important;
    height: 2rem !important;
  }
}
/* --------SquareGrayButton usable classes end---------------- */

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/* --------mainSquareButton usable classes start-------------- */
.mainSquareButton-extra-1 {
  padding: 1rem 3.5rem !important;
  p {
    margin-left: 0 !important;
    font-family: var(--main-font-secondary) !important;
  }
}
/* --------mainSquareButton usable classes end---------------- */

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

/* --------Common Useful Classes Start-------------- */

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-row-full-width-flex-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-grow-container {
  flex-grow: 1;
}

.rotate-180-Deg svg {
  transform: rotate(180deg);
}
.rotate-90-Deg > svg {
  transform: rotate(270deg) !important;
}

.button-icon-swap {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 0.3rem !important;
  }
}

.margin-left-right-1rem {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

.quick__message {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: $main-light-gray-font-color-5;
}

.hide {
  display: none !important;
}
/* --------Common Useful Classes End-------------- */

/* ----------- pagination styles start ----------- */
.pagination {
  width: 100%;
  list-style: none;
  padding-left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  gap: 0.5rem;
  height: 7rem;
  flex-wrap: wrap;

  /*  @media only screen and (max-width: 750px) {
    width: 100%;
    font-size: 0.8rem;
    gap: 0.2rem;
  } */

  &__page-num {
    padding: 0.8rem 1.5rem;
    font-weight: 500 !important;
    font-family: var(--main-font-primary);
    transition: $normal-transition;
    background-color: var(--main-background-color);
    border-color: transparent;
    color: var(--main-btn-color);
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border-radius: $button-border-radius;
    border: 0.5px solid var(--containers-border-color);
    /* -webkit-box-shadow: var(--main-box-shadow);
    box-shadow: var(--main-box-shadow); */

    &:hover {
      border: 1px solid var(--main-btn-color);
      color: var(--main-btn-color);
    }
    &:active {
      box-shadow: none;
    }

    &-disabled {
      cursor: not-allowed;
      box-shadow: none;
      background-color: var(--gray-btn-light-background-color);
      color: var(--gray-btn-light-text-color);
      border: 0.5px solid var(--containers-border-color);
      &:hover {
        background-color: var(--gray-btn-light-background-color);
        color: var(--gray-btn-light-text-color);
        border: 0.5px solid var(--containers-border-color);
      }
    }
  }

  &__active {
    background-color: var(--main-btn-color);
    color: var(--main-btn-text-color);
    border: 0.5px solid var(--main-btn-colo);
    transition: $normal-transition;
    &:hover {
      color: var(--main-btn-text-color);
      border: 0.5px solid var(--main-btn-colo);
    }
  }
}
/* ----------- pagination styles end ------------- */

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton-loader-dark {
  background: linear-gradient(90deg, #121212 25%, #1f1f1f 50%, #121212 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
}
.skeleton-loader-light {
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
}

/* ================================Dummy chart styles start==================================== */
.chartLayout {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-dummy-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: #8b909a;
    margin-bottom: 0;
    margin-top: 1rem;
    // text-align: center;
  }

  &--content {
    display: flex;
    width: 100%;
    // align-items: center;
    // flex-wrap: wrap;
    gap: 2rem;

    &-small {
      flex: 2;

      @media only screen and (max-width: 1400px) {
        flex: 1;
      }
    }

    &-large {
      flex: 3;

      @media only screen and (max-width: 1400px) {
        flex: 1;
      }
    }

    @media only screen and (max-width: 1100px) {
      width: 100%;
      flex-direction: column;
      gap: 0;
    }
  }
}
/* ================================Dummy chart styles end====================================== */

/* ================================World map chart styles start==================================== */
.worldMapChart {
  position: relative;
  &__zoom {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      cursor: pointer;
      border: 1px solid #646464;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $normal-transition;
      background-color: var(--sidebar-background-color);
      margin-bottom: 0.5rem;

      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }

      svg {
        fill: #646464;
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  &__tooltip {
    background-color: var(--sidebar-background-color);
    border: 1px solid #646464;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    &--name {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--main-text-color);
    }

    &--count {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--main-text-color);
    }
  }
}

.userDemographicsMapChart {
  display: flex;
  gap: 1rem;
  height: 40rem;
  width: 100%;
  font-family: var(--main-font-primary);
  @media only screen and (max-width: 850px) {
    height: 56rem;
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }

  &__map {
    border-radius: 0.7rem;
    height: 100%;
    width: 50%;
    border: 1px solid var(--main-border-color);

    @media only screen and (max-width: 850px) {
      width: 100%;
      height: 30rem;
    }
  }
  &__stats {
    padding: 1rem;
    padding-top: 0;
    padding-right: 0;
    width: 50%;
    // background-color: gray;
    height: 100%;
    @media only screen and (max-width: 850px) {
      width: 100%;
      margin-top: 1rem;
      padding: 0;
    }

    &--title {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--main-text-color);
      margin-bottom: 1rem;
    }

    &--content {
      display: flex;
      flex-direction: column;
      height: 36.5rem;
      gap: 1rem;
      overflow-y: auto;
      -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
      scrollbar-width: none; /*Hide scrollbar for Firefox */

      @media only screen and (max-width: 850px) {
        height: 20rem;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-radius: 0.5rem;
        // background-color: var(--containers-background-color);
        transition: $normal-transition;
        border: 1px solid var(--main-border-color);
        cursor: pointer;

        &:hover {
          background-color: var(--subPageLinkHolder-link-hover-color);
        }

        &-right {
          &-name {
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--main-text-color);
          }

          &-count {
            font-size: 1.2rem;
            font-weight: 400;
            color: $main-light-gray-font-color-3;
          }
        }

        &-left {
          display: flex;
          align-items: center;
          gap: 1rem;

          &-percentage {
            font-size: 1.2rem;
            font-weight: 500;
            color: var(--main-btn-text-color);
            background-color: #949494;
            padding: 0.5rem 1rem;
            border-radius: 2rem;
          }
        }
      }
    }
  }
}

/* ================================World map chart styles End====================================== */

/* ================================Time Range Picker Start====================================== */
/* Day button font size */
.MuiPickersDay-root {
  font-size: 1.2rem !important;
}

/* Calendar header font size */
.MuiPickersCalendarHeader-label {
  font-size: 1.5rem !important;
}

/* Input field font size */
.MuiInputBase-root {
  font-size: 1.2rem !important;
}

/* Placeholder and input text font size */
.MuiInputBase-input {
  font-size: 1.2rem !important;
}

/* Weekday labels font size */
.MuiDayCalendar-weekDayLabel {
  font-size: 1.5rem !important;
  font-weight: 500;
  color: var(--main-text-color);
}

/* ================================Time Range Picker End====================================== */

.small-main-btn {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.code__snippet {
  pre {
    // background-color: #111827 !important;
    // color: white !important;
    border-radius: 0.8rem;

    // span {
    //   color: white !important;
    // }
  }
}
