@import '../abstracts/variables.sass';

.message {
  background-color: var(--sidebar-background-color);
  border: 0.5px solid var(--main-border-color);
  border-radius: $normal-border-radius;
  -webkit-box-shadow: 0px 7px 15px -11px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 7px 15px -11px rgba(0, 0, 0, 0.67);
  position: fixed;
  z-index: 2000;
  width: 38rem;
  top: 2rem;
  right: 3rem;
  overflow: hidden;
  padding: 2rem 1.5rem;
  display: flex;
  max-height: 20rem;

  @media only screen and (max-width: 390px) {
    width: 35rem;
  }
  @media only screen and (max-width: 375px) {
    width: 33rem;
  }
  @media only screen and (max-width: 318px) {
    width: 30rem;
  }
  @media only screen and (max-width: 255px) {
    width: 25rem;
  }

  &[data-msgtype='success'] {
    .message__left--icon {
      background-color: #dcfce7;
      svg {
        fill: #16a34a;
      }
    }
  }
  &[data-msgtype='error'] {
    background-color: #dc2626;
    border: 0.5px solid #dc2626;
    .message__left--icon {
      background-color: #fee2e2;
      svg {
        fill: #dc2626;
      }
    }

    .message__right {
      h1 {
        color: #fee2e2;
      }
      p {
        color: #fee2e2;
      }
    }
  }
  &[data-msgtype='warning'] {
    .message__left--icon {
      background-color: #fef9c3;
      svg {
        fill: #ca8a04;
      }
    }
  }
  &[data-msgtype='info'] {
    .message__left--icon {
      background-color: #dbeafe;
      svg {
        fill: #2563eb;
      }
    }
  }

  &__left {
    &--icon {
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #dcfce7;
      border-radius: 50%;
      margin-right: 1rem;
      svg {
        height: 2rem;
        width: 2rem;
        fill: #16a34a;
      }
    }
  }
  &__right {
    h1 {
      font-family: var(--main-font-primary);
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 600;
      color: var(--main-text-color);
      margin-bottom: 1;
    }
    p {
      font-family: var(--main-font-primary);
      font-size: 1.4rem;
      line-height: 1.5rem;
      font-weight: 400;
      margin-bottom: 0;
      color: var(--main-text-color);
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    svg {
      height: 1.8rem;
      width: 1.8rem;
      fill: $main-light-gray-font-color-3;
    }
  }
}
