@import '../abstracts/variables.sass';

.aiTagInput-outer {
  margin-top: 1.3rem;
  width: 100%;

  label {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 600 !important;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }
}

/* 
 &-info {
    display: none;
  }

  &[data-show-info='true'] {
    &-info {
      height: 4.5rem;
      width: 4.5rem;
      border: $input-border-1;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 2rem;
        height: 2rem;
        fill: var(--main-text-color);
      }
    }
  }

  
*/

.aiTagInput--inner {
  &-info {
    display: none;
  }
}

.aiTagInput--inner[data-show-info='true'] {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  .aiTagInput--inner-info {
    margin-top: 0.3rem;
    height: 4.5rem;
    width: 4.5rem;
    border: $input-border-1;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-background-color);
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
      fill: var(--main-text-color);
    }
  }

  .aiTagInput {
    background-color: #111827;
    input {
      background-color: #111827;
      color: #ffffff;

      &::placeholder {
        color: #ffffff;
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
          'Liberation Mono', 'Courier New', monospace;
        font-weight: 500;
      }
    }
  }

  .aiTagInput__list--item span {
    color: #ffffff;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
    font-weight: 500;
  }
}

.aiTagInput {
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 7.6rem;
  transition: $normal-transition;
  flex-wrap: wrap;
  background-color: var(--input-background-color);
  margin-top: 0.3rem;
  padding: 1rem;
  overflow-y: auto;
  -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
  scrollbar-width: none; /*Hide scrollbar for Firefox */
  width: 25rem;
  @media only screen and (max-width: 450px) {
    width: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    border: $input-border-2;
  }
  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  input:focus + .loginInput > .loginInput__left > svg {
    fill: $main-light-gray-font-color-3;
  }

  input {
    border: none;
    width: 100%;
    // height: 3.5rem;
    padding-right: 2rem;
    background-color: var(--input-background-color);
    color: var(--main-text-color);
    margin-bottom: 0.5rem;
    &:focus {
      outline: transparent;
    }
  }

  .label-validation {
    color: $web-color-danger !important;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -0.7rem;

    &--item {
      width: auto;
      height: auto;
      display: flex;
      // align-items: center;
      justify-content: center;
      color: var(--main-btn-text-color);
      padding: 0 0.8rem;
      font-size: 1.4rem;
      list-style: none;
      border-radius: 0.6rem;
      margin: 0 0.8rem 0.8rem 0;
      background-color: var(--main-brand-color);

      &-title {
        /* margin-top: 3px; */
        color: var(--main-btn-text-color);
        font-weight: 500;
      }
      span {
        max-width: 20rem;
        word-wrap: break-word; /* or white-space: normal; */
        color: var(--main-btn-text-color);
      }

      &-icon {
        margin-top: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.6rem;
        text-align: center;
        margin-left: 0.8rem;

        cursor: pointer;

        svg {
          fill: var(--main-btn-text-color);
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }
}

.aiTagInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1.8rem;
  margin-left: 0.2rem;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 750px) {
  .aiTagInput-validation-text {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .aiTagInput-validation-text {
    max-width: 30rem;
  }
}
