@import '../../abstracts/variables.sass';

.dashboardFilter {
  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 1rem;
    border: 0.5px solid var(--containers-border-color);
    border-radius: 0.5rem;
    // overflow: hidden;
    padding: 0.9rem 1.5rem;
    cursor: pointer;
    position: relative;
    transition: $normal-transition;

    @media only screen and (max-width: 600px) {
      padding: 0.8rem;
    }

    li {
      list-style: none;
    }

    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }

    &--title {
      font-size: 1.35rem;
      font-family: var(--main-font-primary);
      color: var(--main-text-color);
      font-weight: 500;
      margin-bottom: 0rem;
      line-height: 1.5rem;

      @media only screen and (max-width: 600px) {
        display: none;
      }

      span {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }

    &-mobile {
      display: none;
      svg {
        height: 2rem;
        width: 2rem;
        fill: var(--main-text-color);
      }

      @media only screen and (max-width: 600px) {
        display: flex;
      }
    }

    &--filters {
      box-shadow: $normal-box-shadow-plus;
      position: absolute;
      z-index: 2;
      top: 3.5rem;
      left: 0;
      right: 0;
      background-color: var(--sidebar-background-color);
      border: 0.5px solid var(--containers-border-color);
      border-radius: 0.5rem;
      overflow: hidden;
      font-weight: 400;
      //   padding: 0.5rem 0;
      @media only screen and (max-width: 600px) {
        width: 15rem;
        left: auto;
      }

      li {
        padding: 0.3rem 1rem;
        transition: $normal-transition;
        color: var(--main-text-color);
        &:hover {
          background-color: var(--subPageLinkHolder-link-hover-color);
        }
      }
    }
  }

  /*------------------------ Event Filter Start ----------------------------*/
  &__event {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--containers-border-color);
    border-radius: 0.5rem;
    padding: 0.9rem 1.5rem;
    cursor: pointer;
    position: relative;
    transition: $normal-transition;

    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }

    &-mobile {
      display: none;
      padding: 0.8rem;
      svg {
        height: 2rem;
        width: 2rem;
        fill: var(--main-text-color);
      }
    }

    &-desktop {
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      &-mobile {
        display: flex;
      }

      &-desktop {
        display: none;
      }
    }

    &--title {
      font-size: 1.35rem;
      font-family: var(--main-font-primary);
      color: var(--main-text-color);
      font-weight: 500;
      margin-bottom: 0rem;
      line-height: 1.5rem;

      span {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }

    &--popup {
      width: 100%;
      padding-bottom: 25px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      &-text {
        margin-bottom: 1rem;
        padding: 1rem;
        h3 {
          font-size: 1.8rem;
          font-weight: 600;
          color: var(--main-text-color);
          margin-bottom: 0rem !important;
        }
        span {
          font-size: 1.5rem;
          font-weight: 400;
          color: var(--main-text-color);
        }
      }

      &-list {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        flex-wrap: wrap;
        gap: 1rem;
        max-width: 40rem;
        @media only screen and (max-width: 600px) {
          max-width: 100%;
        }

        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.1px solid $main-light-gray-font-color-2;
          border-radius: 0.5rem;
          height: 6rem;
          width: 15rem;
          padding: 1rem;
          font-weight: 500;
          cursor: pointer;
          font-size: 1.5rem;
          transition: $normal-transition;
          color: var(--main-text-color);
          &:hover {
            background-color: var(--subPageLinkHolder-link-hover-color);
          }
        }
      }
    }
  }
}

.selected {
  background-color: var(--subPageLinkHolder-link-hover-color);
  border: 0.1px solid var(--main-text-color);
}
