@import '~antd/dist/antd.css';

.App {
  background-color: var(--main-background-color);
  position: relative;
  height: 100vh;

  overflow-y: auto;
  -ms-overflow-style: none; //Hide scrollbar for IE and Edge
  scrollbar-width: none; //Hide scrollbar for Firefox

  //Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  /* -------- Styles for White Label Theme-1 Start ------- */
  &[data-is-white-label='true'] {
    .pageWrapper {
      min-height: 100% !important;
      height: 100% !important;
      margin-top: 0 !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;

      &__content {
        &--sidebar {
          min-height: 100%;
          height: 100%;
        }
        &--page {
          margin-left: 0 !important;

          // .subPageInnerContentHolder__content--top-buttons-backBtn {
          //   display: none !important;
          // }

          // .subPageInnerContentHolder__content--top-right-button {
          //   display: none !important;
          // }
        }
      }
    }

    .topbar {
      display: none !important;
    }
    .newHeader {
      display: none !important;
    }
    .sidebarTheme1 {
      display: none !important;
    }
    .pageWrapper--footer {
      display: none !important;
    }
  }
  /* -------- Styles for White Label Theme-1 End ------- */

  /* -------- Styles for White Label Theme-2 Start ------- */
  &[data-is-white-label='true'] {
    .pageWrapperTheme2 {
      background-color: var(--main-background-color);
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      overflow: visible !important;

      &__content {
        &--sidebar {
          min-width: 0 !important;
        }

        &--page {
          min-height: 100vh !important;
          width: 100% !important;
          padding-top: 0 !important;
          flex-grow: 1 !important;
        }
      }
    }

    .subPageLinkHolder {
      width: calc(100% - 4rem) !important;
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }

    .topbar {
      display: none !important;
    }
    .headerTheme2 {
      display: none !important;
    }
    .sidebarTheme2 {
      display: none !important;
    }
    .pageWrapperTheme2--footer {
      display: none !important;
    }
  }
  /* -------- Styles for White Label Theme-2 End ------- */
}
