.recommendationsTrial {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__title {
    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0;
    }

    span {
      font-size: 1.6rem;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-bottom: 2rem;
    }
  }

  &__button {
    margin-top: 3rem;
    font-size: 1.6rem;
    background-color: var(--main-btn-color);
    color: var(--main-btn-text-color);
    font-weight: 500;
    font-family: var(--main-font-primary) !important;
    padding: 0.9rem 6rem;
    border-radius: 1rem;
    cursor: pointer;
  }
}
