@import '../../abstracts/variables.sass';

.sidebarTheme2 {
  padding: 1.5rem 2rem;
  //   border: $input-border-small;
  border-color: #ada5a578;
  box-shadow: $normal-box-shadow;
  background-color: var(--sidebar-background-color);
  transition: $normal-transition;
  width: $theme-2-sidebar-width;
  position: fixed;
  // top: $header-height;
  bottom: 6rem;
  z-index: 2;
  top: 0;
  border-right: 1px solid var(--main-border-color) !important;

  overflow-y: auto;
  -ms-overflow-style: none; //Hide scrollbar for IE and Edge
  scrollbar-width: none; //Hide scrollbar for Firefox

  //Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 750px) {
    border-right: 1px solid transparent !important;
    bottom: 0rem;
  }

  /*---- SIDEBAR MINIMIZER STYLES START ----*/
  &[data-is-sidebar-minimized='true'] {
    padding: 1rem;
    width: 7rem;

    .sidebarTheme2__top {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 1rem;
      &--left {
        // margin-left: 1rem;
        h2 {
          display: none;
        }
        img {
          height: 4.5rem;
          width: 4.5rem;
        }
      }
      &--right {
        margin-left: 1rem;
        margin-bottom: 1.5rem;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .sidebarTheme2__bottom {
      align-items: flex-start;
      .sidebarTheme2__bottom--title1,
      .sidebarTheme2__bottom--title2 {
        display: none;
      }

      &--linkItem {
        height: 4.5rem;
        width: 4.5rem;
        justify-content: center;
        &-left {
          span {
            display: none;
          }
          svg {
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }

    .sidebarTheme2__profile {
      width: 7rem;
      border-top: 0.5px solid transparent;
      &--content {
        justify-content: center;
        &-left {
          span {
            display: none;
          }
          svg {
            height: 2rem;
            width: 2rem;
            margin-right: 0;
          }
        }
        &-right {
          display: none;
        }
      }
    }
  }
  /*---- SIDEBAR MINIMIZER STYLES END   ----*/

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    &--left {
      display: flex;
      align-items: center;
      img {
        height: 5rem;
        min-width: 5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        /* height: 100%;
        width: 100%; */
        object-fit: contain;
      }

      h2 {
        margin-left: 1rem;
        font-size: 2rem;
        font-family: var(--main-font-primary);
        color: var(--main-text-color);
        font-weight: 500 !important;
        margin-bottom: 0.2rem;
        line-height: 2rem;
      }

      p {
        // color: $main-light-gray-font-color-3;
        color: var(--main-text-color);
        font-size: 1.2rem;
        font-family: var(--main-font-secondary) !important;
        margin-bottom: 0rem;
      }
    }

    &--right {
      height: 2.5rem;
      svg {
        transition: $normal-transition;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
      }

      @media only screen and (max-width: 750px) {
        display: none;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;

    &--title1,
    &--title2 {
      width: 100%;
      text-align: left;

      span {
        margin-left: 1rem;
        font-family: var(--main-font-primary);
        // color: #8b909a;
        color: var(--main-text-color);
        font-size: 1.1rem;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &--title1 {
      margin-bottom: 0.5rem;
    }
    &--title2 {
      margin-top: 1.5rem;
    }

    &--linkItem {
      &-goBack {
        // justify-content: space-evenly;

        svg {
          transform: rotate(180deg);
        }
      }

      width: 100%;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   border: $white-border;
      background-color: var(--sidebar-background-color);
      border-radius: $normal-border-radius;
      transition: $normal-transition;
      padding: 0rem 1rem;
      margin: 0.25rem 0rem;
      cursor: pointer;

      &[data-sub_page_link_holder='true'] {
        background-color: var(--subPageLinkHolder-link-hover-color);

        span {
          color: var(--main-text-color);
        }
      }
      &:hover[data-sub_page_link_holder='true'] {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }
      &[data-sub_page_link_holder='true'] &-left svg {
        // fill: var(--main-brand-color);
        fill: var(--main-text-color);
      }
      &[data-sub_page_link_holder='false'] &-right svg {
        display: none;
      }

      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }

      &:first-child {
        margin: 0rem;
        margin-bottom: 0.5rem;
      }
      &:last-child {
        margin: 0rem;
        margin-top: 0.5rem;
      }

      &-left {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.6rem;
          height: 1.6rem;
          // fill: #8b909a;
          fill: var(--main-text-color);
        }

        span {
          margin-left: 1rem;
          font-family: var(--main-font-primary);
          // color: #8b909a;
          color: var(--main-text-color);
          font-size: 1.4rem;
          font-weight: 500;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.9rem;
          // fill: var(--main-brand-color);
          // fill: var(--main-btn-text-color);
          fill: var(--main-text-color);
        }
      }
    }
  }

  &__profile {
    background-color: var(--sidebar-background-color);
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 1rem 2rem;
    border-top: 0.5px solid #ada5a578;
    margin-top: 1rem;
    width: $theme-2-sidebar-width;
    border-right: 1px solid var(--main-border-color) !important;
    transition: $normal-transition;
    @media only screen and (max-width: 750px) {
      display: none;
    }

    &--content {
      border-radius: 0.7rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: $normal-transition;
      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }
      &-left {
        display: flex;
        align-items: center;
        svg {
          width: 1.4rem;
          height: 1.4rem;
          fill: var(--main-text-color);
          margin-right: 1.5rem;
        }

        span {
          font-family: var(--main-font-primary);
          font-weight: 600 !important;
          margin-bottom: 0rem;
          line-height: 2rem;
          color: var(--main-text-color);
          font-size: 1.4rem;
          font-weight: 500;
        }
      }

      &-right {
        svg {
          transition: $normal-transition;
          width: 1rem;
          height: 1rem;
          fill: var(--main-text-color);
          transform: rotate(90deg);
        }
      }
    }
  }
}

.sidebarTheme2__profile[data-is-sidebar-profile-open='true'] {
  .sidebarTheme2__profile--content-right svg {
    transform: rotate(270deg);
  }
}
