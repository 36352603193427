@import '../../abstracts/variables.sass';

// Define the keyframes for the color change
@keyframes colorSwitch {
  0% {
    fill: var(--main-text-color);
  }
  50% {
    fill: #808080;
  }
  100% {
    fill: var(--main-text-color);
  }
}

.quickOnboardingReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    font-size: 2.4rem;
    font-weight: 700 !important;
    color: var(--main-text-color);
    margin-bottom: 2rem;
  }
  &__icon {
    svg {
      width: 5rem;
      height: 5rem;
      fill: #000000; // Default
      animation: colorSwitch 2s infinite;
      transition: all 0.3s ease-in-out;
    }
  }
  &__info {
    font-size: 1.6rem;
    color: var(--main-text-color);
    text-align: center;
    margin-top: 2rem;
  }

  &__button {
    width: 100%;
    margin-top: 2rem;

    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: rgb(22, 22, 22);
      &:hover {
        background-color: rgb(36, 36, 36);
      }
    }
  }

  &__button--light {
    width: 100%;
    margin-top: 2rem;

    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: transparent;
      border: 1px solid var(--main-text-color);
      color: var(--main-text-color);
    }
  }
}
