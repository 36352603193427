@import '../../abstracts/variables.sass';

/*---- SIDEBAR MINIMIZER STYLES START ----*/
.pageWrapperTheme2--outer[data-is-sidebar-minimized-wrapper='true'] {
  .pageWrapperTheme2__content {
    &--sidebar {
      min-width: 7rem;
    }
  }

  .pageWrapperTheme2--footer {
    padding-left: 9rem;
  }
}
/*---- SIDEBAR MINIMIZER STYLES END -----*/

/*---- SIDEBAR MOBILE STYLES START ----*/
.pageWrapperTheme2--outer[data-is-sidebar-open='true'] {
  @media only screen and (max-width: 750px) {
    .sidebarTheme2 {
      left: 0rem;
    }

    .pageWrapperTheme2__content--page {
      filter: blur(3px);
    }

    .pageWrapperTheme2--footer {
      filter: blur(3px);
    }
  }
}
.pageWrapperTheme2--outer[data-is-sidebar-open='false'] {
  @media only screen and (max-width: 750px) {
    .pageWrapperTheme2--footer {
      padding-left: 2rem;
    }
  }
}

/*---- SIDEBAR MOBILE STYLES END -----*/

.pageWrapperTheme2 {
  background-color: var(--main-background-color);
  // padding-top: $header-height;
  overflow: hidden;

  @media only screen and (max-width: 750px) {
    padding-top: $header-height;
  }

  &--outer {
  }

  &--footer {
    padding-left: calc(#{$theme-2-sidebar-width + 2rem});
    padding-right: 2rem;
  }

  &__content {
    display: flex;

    &--sidebar {
      min-width: $theme-2-sidebar-width;
      transition: all 0.5s ease;

      @media only screen and (max-width: 750px) {
        min-width: 0;
        .sidebarTheme2 {
          left: calc(-#{$theme-2-sidebar-width + 2rem});
        }
      }
    }

    &--page {
      // min-height: 100vh;
      min-height: calc(100vh - #{$header-height - 1rem});
      width: 50%;
      // padding-top: 2rem;
      flex-grow: 1;

      @media only screen and (max-width: 750px) {
        min-height: calc(100vh - #{$header-height + 5.4rem});
      }
    }
  }
}
