@import '../abstracts/variables.sass';

.mainSquareButton {
  padding: 0.8rem 2rem;
  // font-size: 1.4rem;
  font-family: var(--main-font-primary);
  transition: $normal-transition;
  background-color: var(--main-btn-color);
  // background-color: var(--background-color);
  // border-color: transparent;
  color: var(--main-btn-text-color);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  // border-radius: 0.7rem;
  // font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: var(--main-box-shadow);
  box-shadow: var(--main-box-shadow);

  //new styles
  /*  border: 0.2rem solid #00dcb7; */
  border-radius: $button-border-radius;
  font-size: 1.4rem;
  font-weight: 700 !important;

  &[data-main-square-button-normal='true'] {
    box-shadow: none;
  }
  &[data-main-square-button-type='danger'] {
    color: $web-color-danger;
    background-color: var(--gray-btn-light-background-color);
    border: $input-border-small;
    box-shadow: none;

    &:hover {
      background-color: var(--gray-btn-light-hover-background-color);
      /* color: $main-light-gray-font-color-2; */
    }
  }
  &[data-main-square-button-type='light'] {
    background-color: var(--gray-btn-light-background-color);
    color: var(--gray-btn-light-text-color);
    border: $input-border-small;
    box-shadow: none;

    &:hover {
      background-color: var(--gray-btn-light-hover-background-color);
      /* color: $main-light-gray-font-color-2; */
    }

    svg {
      fill: var(--gray-btn-light-text-color);
      height: 1.4rem;
      margin-right: 0.3rem;
    }
  }
  &[data-main-square-button-type='filter'] {
    background-color: #5fd7ea;
    border: $input-border-small;
    border-color: transparent;
    color: var(--main-btn-text-color);
    box-shadow: $normal-box-shadow-plus;

    &:hover {
      background-color: #51c6d7;
      /* color: $main-light-gray-font-color-2; */
    }

    svg {
      fill: var(--main-btn-text-color);
      height: 1.4rem;
      margin-right: 0.3rem;
    }
  }
  &[data-main-square-button-type='delete'] {
    font-weight: 800 !important;
    color: #ffffff !important;
    background-color: $web-color-danger !important;
    border-color: rgb(161, 0, 0) !important;
    border: $input-border-small;
    box-shadow: none;

    &:hover {
      background-color: rgb(222, 113, 113) !important;
      /* color: $main-light-gray-font-color-2; */
    }
  }

  svg {
    fill: var(--main-btn-text-color);
    height: 1.4rem;
    margin-right: 0.3rem;
  }

  p {
    margin-bottom: 0;
    font-weight: 500 !important;
    font-family: var(--main-font-primary) !important;
  }

  &:disabled {
    &:hover {
      background-color: $main-light-gray-font-color-7 !important;
    }
    cursor: not-allowed !important;
    background-color: $main-light-gray-font-color-7 !important;
    -webkit-box-shadow: 0 14px 26px -12px rgba(196, 196, 196, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(201, 201, 201, 0.2) !important;
    box-shadow: none !important;
    border-color: rgb(147, 147, 147) !important;
    color: var(--main-btn-text-color) !important;
  }

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: var(--main-btn-hover-color);
  }
}
