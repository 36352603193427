.splash-screen {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: var(--containers-background-color);
}

.splash-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.splash-screen img {
  animation: pulsate 600ms alternate infinite ease;
  width: 40%;
  object-fit: contain;
}
.splash-screen-lottie {
  width: 40%;
  object-fit: contain;
}
.splash-screen h2 {
  margin-top: 35px;
  text-align: center;
  font-family: var(--main-font-primary);
  font-weight: 200 !important;
  font-size: 20px;
  line-height: 30px;
  color: var(--main-text-color);
}
@keyframes pulsate {
  from {
    opacity: 0.3;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1.1);
  }
}
